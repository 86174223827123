<template>
  <main class="page-container" id="sme-apply">
    <common-list :div-class="['page-component']" :is-loading="isLoading" :is-no-result="isNoResult">
      <!-- 중앙부처 지원사업 안내 -->
      <div>
        <div class="apply-header">
          <div v-if="isMobile" class="section-notice-arrow">
            <a href="/support" class="btn-arrow">
              <i class="icon-arrow-prev">이전 페이지</i>
            </a>
            <a href="/support2" class="btn-arrow">
              <i class="icon-arrow-next">다음 페이지</i>
            </a>
          </div>
          <h4 class="title">중앙부처 지원사업 안내</h4>
          <div class="btn_search">
            <input style="height: 50px" v-model="sprtBizNm" type="text" class="form_search" placeholder="사업명을 입력해 주세요" @keyup.enter="getSupportBusinessList"/>
            <button class="ico_search" @click="getSupportBusinessList">
              검색
            </button>
          </div>
        </div>
        <div class="second_wrap">
          <ul v-if="isMobile" class="tab_wrap" id="tab_wrap">
            <li v-for="(option, idx) in tempCode" :key="idx" style="width: 100%">
              <button type="button" v-bind:class="[deptTyp === option.cmmnCdVal ? 'tab_list on' : 'tab_list',]" @click="changeDeptTyp(option.cmmnCdVal, option.cmmnCdValNm)">
                {{ option.cmmnCdValNm }}
              </button>
            </li>
          </ul>

          <ul v-else class="tab_wrap" id="tab_wrap">
            <li v-for="(option, idx) in deptTypCdOptions" :key="idx">
              <button type="button" v-bind:class="[deptTyp === option.cmmnCdVal ? 'tab_list on' : 'tab_list',]" @click="changeDeptTyp(option.cmmnCdVal, option.cmmnCdValNm)">
                {{ option.cmmnCdValNm }}
              </button>
            </li>
          </ul>

          <div v-if="isMobile" class="accordion">
            <input type="checkbox" id="menu"/>
            <label class="dept" for="menu" @click="btnClick" >
              <span>펼쳐보기</span>
            </label>
            <ul class="tab_wrap">
              <li v-for="(option, idx) in deptTypCdOptions" :key="idx">
                <button type="button" v-bind:class="[deptTyp === option.cmmnCdVal ? 'tab_list on' : 'tab_list',]" @click="changeDeptTyp(option.cmmnCdVal, option.cmmnCdValNm)">
                  {{ option.cmmnCdValNm }}
                </button>
              </li>
            </ul>
          </div>
          <div class="tbl_wrap">
            <table>
              <caption>
                중앙부처 지원사업의 부처명,사업연도,사업명,상세보기 제공
              </caption>
              <colgroup>
                <col v-if="!isMobile"width="15%" />
                <col v-if="!isMobile"width="10%" />
                <col width="*" />
                <col v-if="!isMobile"width="15%" />
              </colgroup>
              <thead>
              <tr>
                <th v-if="!isMobile">부처명</th>
                <th v-if="!isMobile">사업연도</th>
                <th>사업명</th>
                <th v-if="!isMobile">상세보기</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in items" class="apply-list-item" :key="idx">
                <td v-if=" !isMobile && (idx === 0 || item.deptTyp !== items[idx-1].deptTyp)" :rowSpan="getRowSpan(items, item)">
                  {{item.deptNm}}
                </td>
                <td v-if="!isMobile" >{{ item.sprtBizYr }}년</td>
                <td v-if="!isMobile" class="title">
                  <span>{{ item.sprtBizNm }}</span>
                </td>

                <td v-if="isMobile" class="title">
                  <a :href="item.aplyUrl" title="상세보기" target="_blank" style="pointer-events:auto">
                    <div>
                      <span >{{ item.sprtBizNm }}</span>
                    </div>
                  </a>
                </td>
                <td v-if="!isMobile" >
                  <a :href="item.aplyUrl" title="상세보기" class="btn-secondary btn-detail" target="_blank"style="pointer-events: auto">상세보기</a>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </common-list>
  </main>
</template>

<script>
import {ACT_GET_SHOP_MALL_COMMON_CODE_LIST, ACT_GET_SUPPORT_BUSINESS_LIST} from "../../../store/_act_consts";
import {getCheckItems, getItems, lengthCheck} from "../../../assets/js/utils";
import { MUT_SET_COMMON_CODE_ITEMS } from "../../../store/_mut_consts";
import CommonList from "../../../components/common/CommonList";
import { mapGetters } from "vuex";

export default {
  name: "Support",
  components: {
    CommonList,
  },
  data: () => ({
    isLoading: false,
    bizCtgrDcd: "1021002",
    deptTyp: '',
    deptTypNm: '전체',
    sortCd: "",
    sprtBizNm: "",
    items: [],
    tempCode:[{cmmnCd:'GOVE_TYP_CD', cmmnCdVal:'', cmmnCdValNm:'전체'}],
  }),
  watch: {
    deptTyp() {
      this.getSupportBusinessList(true);
    },
  },
  computed: {
    ...mapGetters("common", ["isMobile", "commonCode"]),
    deptTypCdOptions() {
      this.commonCode.unshift({cmmnCd:'GOVE_TYP_CD', cmmnCdVal:'', cmmnCdValNm:'전체'})
      return this.commonCode.filter((x) => x.cmmnCd === "GOVE_TYP_CD");
    },
    isNoResult() {
      return this.items.length === 0;
    },
  },
  created() {
    this.getCommonCdList();
    this.getSupportBusinessList(true);
  },
  methods: {
    getSupportBusinessList(isInit) {
      if (isInit) {
        this.items = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`support/${ACT_GET_SUPPORT_BUSINESS_LIST}`, {
        sortCd: this.sortCd,
        sprtBizNm: this.sprtBizNm,
        bizCtgrDcd: this.bizCtgrDcd,
        deptTyp: this.deptTyp,
      })
          .then((res) => {
            if (lengthCheck(res)) {
              this.items = getItems(res);
            }
            this.isLoading = false;
          })
          .catch((e) => {
            console.error(e);
            this.isLoading = false;
          });
    },
    getCommonCdList() {
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {})
          .then((res) => {
            this.$store.commit(
                `common/${MUT_SET_COMMON_CODE_ITEMS}`,
                getCheckItems(res)
            );
          });
    },
    changeDeptTyp(deptTypCd, deptTypNm) {
      this.deptTyp = deptTypCd;
      this.deptTypNm = deptTypNm;
    },
    btnClick() {
      let element = document.getElementById('tab_wrap');
      element.classList.toggle('show_menu');
    },
    getRowSpan(arr, data) {
      let rowSpan = 0;
      arr.forEach(item => {
        if(item.deptTyp === data.deptTyp) rowSpan++;
      });

      return rowSpan;
    }
  },
};

</script>
